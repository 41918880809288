import Axios from "axios";
import config from "../config";

let devAuthKey = ''


const AuthService = {
	getAuthToken: async (authCode) => {
		try {
			const res = await Axios({
				headers: {
					"x-auth-code": authCode,
				},
				method: 'post',
				url: `${config.PRODWS_URL}/api/login/code`
			});
			return res;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	authenticationKey: function () {
		return localStorage.getItem("spaceio-key") ? localStorage.getItem("spaceio-key") : null
	},

	login: async (username, password) => {
		try {
			const response = await Axios.post(`${config.PRODWS_URL}/api/login`, {
				"username": username,
				"password": password
			})
			const key = (response.headers["x-auth-key"])
			window.localStorage.setItem("spaceio-key", (key))
			return true
		} catch (err) {
			throw (err.response.data.error)
		}
	},

	logout: () => {
		window.localStorage.removeItem("spaceio-key");
	},

	searchAPIModified: async (blessIDAndMacID, type) => {
		let myHeaders = new Headers();
		// myHeaders.append("X-REFERRER", "htts://account.dev.afreespace.com");
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("x-auth-key", window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("x-auth-key", "eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMjIxMzgiLCJhdWQiOiJodHRwczovL2FkbWluLXRlbXAuYWZyZWVzcGFjZS5jb20vP3JlZGlyZWN0X3RvPWh0dHBzJTNBJTJGJTJGYWRtaW4tdGVtcC5hZnJlZXNwYWNlLmNvbSUyRnNwYWNlLWxpc3QuaHRtbCUyMzM2NjUiLCJleHAiOjE3MjEwMDczMzEsImFpZCI6MTIsImlhdCI6MTcyMDM1OTMzMX0.Xj0B1ij2mjMpFbP-yvtFLr-DQVGMmbicap3TAdcJTGdkqVS7mE-DjjCdgUyivOedUBZOdGzLSxXWsugENelWfNn-pKXscXEfqmifvmlXztE3hKH--Bu97i8OfhiZ7KHd-THomYwKEUFl67XPTmRO1MZF2pQI6fZiKrWBfckG-EPSHe9iDnLxgu9cfLXft-hYi_aCAqxuH2-ULmCSoliU1zG0so49pupfWx46JhzHgD5zega1BZXH3XXBV8YQU46uIP89kVRqAAnkvm6cWCsbMJq9iGwHcqgSl8ZHJFy2xIhJsRPFoaQ3_xjct5rTQllu9HsJpX0KMJLte10DtiksxA");

		let raw = JSON.stringify(blessIDAndMacID);

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};

		const response = await fetch(`${config.PRODWS_URL}/api/admin/devices/search?type=${type}`, requestOptions)
		const result = await response.json();
		return result;

	},

	summaryApi: async (macAddress) => {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + window.localStorage.getItem('spaceio-key'));
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Access-Control-Allow-Origin", "*");
		// myHeaders.append("x-auth-key", window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("x-auth-key", "eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMjIxMzgiLCJhdWQiOiJodHRwczovL2FkbWluLXRlbXAuYWZyZWVzcGFjZS5jb20vP3JlZGlyZWN0X3RvPWh0dHBzJTNBJTJGJTJGYWRtaW4tdGVtcC5hZnJlZXNwYWNlLmNvbSUyRnNwYWNlLWxpc3QuaHRtbCUyMzM2NjUiLCJleHAiOjE3MjEwMDczMzEsImFpZCI6MTIsImlhdCI6MTcyMDM1OTMzMX0.Xj0B1ij2mjMpFbP-yvtFLr-DQVGMmbicap3TAdcJTGdkqVS7mE-DjjCdgUyivOedUBZOdGzLSxXWsugENelWfNn-pKXscXEfqmifvmlXztE3hKH--Bu97i8OfhiZ7KHd-THomYwKEUFl67XPTmRO1MZF2pQI6fZiKrWBfckG-EPSHe9iDnLxgu9cfLXft-hYi_aCAqxuH2-ULmCSoliU1zG0so49pupfWx46JhzHgD5zega1BZXH3XXBV8YQU46uIP89kVRqAAnkvm6cWCsbMJq9iGwHcqgSl8ZHJFy2xIhJsRPFoaQ3_xjct5rTQllu9HsJpX0KMJLte10DtiksxA");

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const response = await fetch(`${config.ELECTRIC_LAMBDA_URL}summary?deviceId=${macAddress}`, requestOptions)
		const result = await response.json();
		return result;
	},
	productType: async (productId) => {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + window.localStorage.getItem('spaceio-key'));
		myHeaders.append("Content-Type", "application/json");
		// myHeaders.append("x-auth-key", window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("x-auth-key", "eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMjIxMzgiLCJhdWQiOiJodHRwczovL2FkbWluLXRlbXAuYWZyZWVzcGFjZS5jb20vP3JlZGlyZWN0X3RvPWh0dHBzJTNBJTJGJTJGYWRtaW4tdGVtcC5hZnJlZXNwYWNlLmNvbSUyRnNwYWNlLWxpc3QuaHRtbCUyMzM2NjUiLCJleHAiOjE3MjEwMDczMzEsImFpZCI6MTIsImlhdCI6MTcyMDM1OTMzMX0.Xj0B1ij2mjMpFbP-yvtFLr-DQVGMmbicap3TAdcJTGdkqVS7mE-DjjCdgUyivOedUBZOdGzLSxXWsugENelWfNn-pKXscXEfqmifvmlXztE3hKH--Bu97i8OfhiZ7KHd-THomYwKEUFl67XPTmRO1MZF2pQI6fZiKrWBfckG-EPSHe9iDnLxgu9cfLXft-hYi_aCAqxuH2-ULmCSoliU1zG0so49pupfWx46JhzHgD5zega1BZXH3XXBV8YQU46uIP89kVRqAAnkvm6cWCsbMJq9iGwHcqgSl8ZHJFy2xIhJsRPFoaQ3_xjct5rTQllu9HsJpX0KMJLte10DtiksxA");

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const response = await fetch(`${config.ELECTRIC_LAMBDA_URL}productmodel?productId=${productId}`, requestOptions)
		const result = await response.json();
		return result;
	},
	modelName: async (deviceId) => {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + window.localStorage.getItem('spaceio-key'));
		myHeaders.append("Content-Type", "application/json");

		// myHeaders.append("x-auth-key", window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("x-auth-key", "eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMjIxMzgiLCJhdWQiOiJodHRwczovL2FkbWluLXRlbXAuYWZyZWVzcGFjZS5jb20vP3JlZGlyZWN0X3RvPWh0dHBzJTNBJTJGJTJGYWRtaW4tdGVtcC5hZnJlZXNwYWNlLmNvbSUyRnNwYWNlLWxpc3QuaHRtbCUyMzM2NjUiLCJleHAiOjE3MjEwMDczMzEsImFpZCI6MTIsImlhdCI6MTcyMDM1OTMzMX0.Xj0B1ij2mjMpFbP-yvtFLr-DQVGMmbicap3TAdcJTGdkqVS7mE-DjjCdgUyivOedUBZOdGzLSxXWsugENelWfNn-pKXscXEfqmifvmlXztE3hKH--Bu97i8OfhiZ7KHd-THomYwKEUFl67XPTmRO1MZF2pQI6fZiKrWBfckG-EPSHe9iDnLxgu9cfLXft-hYi_aCAqxuH2-ULmCSoliU1zG0so49pupfWx46JhzHgD5zega1BZXH3XXBV8YQU46uIP89kVRqAAnkvm6cWCsbMJq9iGwHcqgSl8ZHJFy2xIhJsRPFoaQ3_xjct5rTQllu9HsJpX0KMJLte10DtiksxA");

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const response = await fetch(`${config.ELECTRIC_LAMBDA_URL}productdevicegroup?deviceGroupId=${deviceId}`, requestOptions)
		const result = await response.json();
		return result;
	},

	deblessedAPI: async (loginKey, macAddress, deviceId) => {
		let myHeaders = new Headers();
		// myHeaders.append("x-auth-key", "eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiNDk2IiwiYXVkIjoiaHR0cHM6Ly9mYWN0b3J5LWRlYmxlc3MuYWZyZWVzcGFjZS5jb20iLCJleHAiOjE3MjE5NDMzMDAsImFpZCI6MTIsImlhdCI6MTcyMTI5NTMwMH0.Bj3krvtW-jn5YrBhAZ-aonnKGdS_XXBjm_APxlJ0kZIGzKXXxElbrjP12AOysWEjLJp1iE1wSQJwk7AqYlINu4WxARKoqEJroEueQehG9YzMG_9eBX9KZyeKOWbZLLDnru6QmUBkyMDYQVYUROMn4uKn1p2vv_FzMcglw_18jLnBrDIEF4nLhbl56_uHmyqjPdqZ1yW1M2k0rfSibIbWuCWAYB7D_SqVrjQL09246IU_lD3wvZtU1e0cVnVnTp6-ktIzpPTokNB71ZCU0yf8BJ-H0Kqa2btoOdV9Xu-Elf7eKMNMZQBo_0WKjE52kyG59zwoA9uk2jqjZXqb4p0M7w");
		myHeaders.append("Authorization", "Bearer " + window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("x-auth-key", window.localStorage.getItem('spaceio-key'));
		myHeaders.append("content-type", "application/json");

		let raw = JSON.stringify({
			"XElectricImpKey": `${loginKey}`,
			"deviceData": { "data": [{ "type": "device", "id": `${macAddress}` }] },
		});

		let requestOptions = {
			method: 'DELETE',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};
		const response = await fetch(`https://9rplmf5sc2.execute-api.eu-west-1.amazonaws.com/dev/debless?deviceGroupId=${deviceId}`, requestOptions)
		const result = await response.json();
		return result;
	},
	factoryEvent: async (macAddress, blessId) => {
		console.log("in factory event", macAddress, blessId)
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " + window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("Authorization", "Bearer eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMzEwOTc1IiwiYXVkIjoiaHR0cHM6Ly9iZXRhLWFkbWluLmFmcmVlc3BhY2UuY29tLz9yZWRpcmVjdF90bz1odHRwcyUzQSUyRiUyRmJldGEtYWRtaW4uYWZyZWVzcGFjZS5jb20lMkY5NDQlMkZjb250cm9scy5odG1sIiwiZXhwIjoxNzIxMzQwODE1LCJhaWQiOjEyLCJpYXQiOjE3MjA2OTI4MTV9.OisG-KZKJjP0WKx-QUYCtBFvE2H0I3oviavX_YZsYzjEKKoXINQaNlReAUv9HcPQmXjpcqgP46-aciMeZKux_f1HRSvJTHUvjLUIvMROg_fG1CVBDOBScvnB9RShjUuA3fmqMSYuNSrTkfJJn97S-_fQ7v_NqQaIM1us7fEka2HCMgx0Vqz2v6CXdZfBP0W7IQqxNH6FkOmlPAi1BeupXqTvBNg1h81Ibe6efcMQzCELFmH1rE91o1hRj0fuRkhpkuU_SaRWurCmkZTKnLiQRvIWm8T87IYkM8L45ydOMaPJ7I8ZA3nDKogW60fhjKuKpOPSDqiXoRZO0f4Itwp_qQ");

		const raw = JSON.stringify([
			{
				"macId": `${macAddress}`,
				"blessId": blessId
			}
		]);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		};

		const response = await fetch(`${config.BLESSING_LAMBDA_URL}factory_event`, requestOptions)
		const result = await response.json();
		return result;
	},
	deviceConfig: async (macAddress, blessId) => {
		console.log("in device config", macAddress, blessId)

		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " + window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("Authorization", "Bearer eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMzEwOTc1IiwiYXVkIjoiaHR0cHM6Ly9iZXRhLWFkbWluLmFmcmVlc3BhY2UuY29tLz9yZWRpcmVjdF90bz1odHRwcyUzQSUyRiUyRmJldGEtYWRtaW4uYWZyZWVzcGFjZS5jb20lMkY5NDQlMkZjb250cm9scy5odG1sIiwiZXhwIjoxNzIxMzQwODE1LCJhaWQiOjEyLCJpYXQiOjE3MjA2OTI4MTV9.OisG-KZKJjP0WKx-QUYCtBFvE2H0I3oviavX_YZsYzjEKKoXINQaNlReAUv9HcPQmXjpcqgP46-aciMeZKux_f1HRSvJTHUvjLUIvMROg_fG1CVBDOBScvnB9RShjUuA3fmqMSYuNSrTkfJJn97S-_fQ7v_NqQaIM1us7fEka2HCMgx0Vqz2v6CXdZfBP0W7IQqxNH6FkOmlPAi1BeupXqTvBNg1h81Ibe6efcMQzCELFmH1rE91o1hRj0fuRkhpkuU_SaRWurCmkZTKnLiQRvIWm8T87IYkM8L45ydOMaPJ7I8ZA3nDKogW60fhjKuKpOPSDqiXoRZO0f4Itwp_qQ");

		const raw = JSON.stringify([
			{
				"macId": `${macAddress}`,
				"blessId": blessId
			}
		]);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		};

		const response = await fetch(`${config.BLESSING_LAMBDA_URL}device_config`, requestOptions)
		const result = await response.json();
		return result;
	}
}
export default AuthService;