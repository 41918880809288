
import AuthService from "../Auth/auth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";


function Authenticate() {
    var key = localStorage.getItem('spaceio-key')
    const params = new URLSearchParams(window.location.search)
    const navigate = useNavigate();
    const [authCode, setAuthCode] = useState(params.has('xauthcode') ? params.get('xauthcode') : null)
    useEffect(() => {
        async function fetchToken() {
            const result = await AuthService.getAuthToken(authCode);
            localStorage.setItem('spaceio-key', result.headers['X-Auth-Key'] || result.headers['x-auth-key'])


            // let fetchRole = localStorage.getItem("spaceio-user")
            // if (fetchRole) {
            //     try {
            //         const userObject = JSON.parse(fetchRole);
            //         const roles = userObject.roles || [];

            //         // const hasFactoryAdminRole = roles.some(data => data.title.toLowerCase() === 'factory admin');
            //         // const hasBlesserRole = roles.some(data => data.title.toLowerCase() === 'blesser');
            //         const hasFactoryAdminRole = roles.some(data => data.title.toLowerCase() === 'super admin (read only)');
            //         const hasBlesserRole = roles.some(data => data.title.toLowerCase() === 'office User');

            //         if (hasFactoryAdminRole && hasBlesserRole) {
            //             navigate('/approver');
            //         } else if (hasFactoryAdminRole && !hasBlesserRole) {
            //             navigate('/requester');
            //         } else {
            //             window.location.href = 'https://login.afreespace.com/v2?referrer=https://admin-temp.afreespace.com/controls.html';
            //         }


            //     } catch (error) {
            //         console.error("Error parsing user data:", error);
            //     }
            // } else {
            //     console.log("User data not found in local storage.");
            // }
            // navigate('/approver')
            navigate('/requester')


            //react router function to send it to selectfloor route
        }
        fetchToken();
    }, [authCode])

    // if (params.has('xauthcode')) {
    //     console.log('authcode', params.get('xauthcode'))
    //     setAuthCode(params.get('xauthcode'))
    // }

    if (!params.has('xauthcode')) {
        //console.log('redirect to authenticate page')
        window.location.href = `${config.LOGIN_API_URL}/v2?referrer=${config.DOMAIN_UK_REFERRER}`; //dev login or dev domain else prod login or prod domain
        // window.location.href = 'https://login.afreespace.com/v2?referrer=https://factory-debless.afreespace.com';
        // console.log("window", window.location.href);
        // window.location.href = 'https://login.dev.afreespace.com/v2?referrer=https://localhost.afreespace.com:8443';
    }

    //   LoggedinFlag = true
    //console.log("in authenticate", key)
}



export default Authenticate;